var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('NavHeader',{attrs:{"nav":true}}),_c('NavHeaderMobile'),_c('div',{staticClass:"wrapper"},[_c('h2',[_vm._v("Contact Inst Sales")]),_c('div',{staticClass:"title-tips"},[_vm._v(" Free feel to get in touch with us and let us know the best way we can support you ")]),_c('div',{staticClass:"contact-container"},[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"FIRST NAME"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'first_name',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please enter your first name',
                      },
                    ],
                  },
                ]),expression:"[\n                  'first_name',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Please enter your first name',\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"placeholder":"Please enter your first name"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"LAST NAME"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'last_name',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please enter your last name',
                      },
                    ],
                  },
                ]),expression:"[\n                  'last_name',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Please enter your last name',\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"placeholder":"Please enter your last name"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"EMAIL"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'email',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please enter your email',
                      },
                    ],
                  },
                ]),expression:"[\n                  'email',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Please enter your email',\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"placeholder":"Please enter your email"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"COMPANY","has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'company',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please enter your company',
                      },
                    ],
                  },
                ]),expression:"[\n                  'company',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Please enter your company',\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"placeholder":"Please enter your company"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"* INDUSTRY"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'industry',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please select your industry',
                      },
                    ],
                  },
                ]),expression:"[\n                  'industry',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Please select your industry',\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"options":_vm.industryOption,"placeholder":"Please select your industry"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"COUNTRY"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'country',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please select your country',
                      },
                    ],
                  },
                ]),expression:"[\n                  'country',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Please select your country',\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"options":_vm.countryOption,"placeholder":"Please select your country"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"* PHONE NUMBER"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'phone_number',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please enter your phone number',
                      },
                    ],
                  },
                ]),expression:"[\n                  'phone_number',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Please enter your phone number',\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"placeholder":"Please enter your phone number"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"REGISTERED COMPANY ADDRESS"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'company_address',
                  {
                    rules: [
                      {
                        required: true,
                        message:
                          'Please enter your registered company address',
                      },
                    ],
                  },
                ]),expression:"[\n                  'company_address',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message:\n                          'Please enter your registered company address',\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"placeholder":"Please enter your registered company address"}})],1)],1),_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"WEBSITE"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'website',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please enter your website',
                      },
                    ],
                  },
                ]),expression:"[\n                  'website',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Please enter your website',\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"placeholder":"Please enter your website"}})],1)],1),_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"MESSAGE"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'message',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please enter your message',
                      },
                    ],
                  },
                ]),expression:"[\n                  'message',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Please enter your message',\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"placeholder":"Message to sales","autosize":{ minRows: 4 }}})],1)],1)],1),_c('a-form-item',{staticClass:"bottom_container"},[_c('a-button',{staticClass:"goToBtn",attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" Submit ")])],1)],1)],1)]),_c('NewFooter')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }