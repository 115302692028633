<template>
  <div>
    <NavHeader :nav="true" />
    <NavHeaderMobile />
    <div class="wrapper">
      <h2>Contact Inst Sales</h2>
      <div class="title-tips">
        Free feel to get in touch with us and let us know the best way we can
        support you
      </div>
      <div class="contact-container">
        <a-form :form="form" @submit="handleSubmit">
          <a-row :gutter="24">
            <a-col :span="12">
              <a-form-item label="FIRST NAME">
                <a-input
                  placeholder="Please enter your first name"
                  v-decorator="[
                    'first_name',
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Please enter your first name',
                        },
                      ],
                    },
                  ]"
                /> </a-form-item
            ></a-col>
            <a-col :span="12">
              <a-form-item label="LAST NAME">
                <a-input
                  placeholder="Please enter your last name"
                  v-decorator="[
                    'last_name',
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Please enter your last name',
                        },
                      ],
                    },
                  ]"
                /> </a-form-item
            ></a-col>
            <a-col :span="12">
              <a-form-item label="EMAIL">
                <a-input
                  placeholder="Please enter your email"
                  v-decorator="[
                    'email',
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Please enter your email',
                        },
                      ],
                    },
                  ]"
                /> </a-form-item
            ></a-col>
            <a-col :span="12">
              <a-form-item label="COMPANY" has-feedback>
                <a-input
                  placeholder="Please enter your company"
                  v-decorator="[
                    'company',
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Please enter your company',
                        },
                      ],
                    },
                  ]"
                />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="* INDUSTRY">
                <a-select
                  :options="industryOption"
                  placeholder="Please select your industry"
                  v-decorator="[
                    'industry',
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Please select your industry',
                        },
                      ],
                    },
                  ]"
                />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="COUNTRY">
                <a-select
                  :options="countryOption"
                  placeholder="Please select your country"
                  v-decorator="[
                    'country',
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Please select your country',
                        },
                      ],
                    },
                  ]"
                />
              </a-form-item>
            </a-col>

            <a-col :span="12">
              <a-form-item label="* PHONE NUMBER">
                <a-input
                  placeholder="Please enter your phone number"
                  v-decorator="[
                    'phone_number',
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Please enter your phone number',
                        },
                      ],
                    },
                  ]"
                />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="REGISTERED COMPANY ADDRESS">
                <a-input
                  placeholder="Please enter your registered company address"
                  v-decorator="[
                    'company_address',
                    {
                      rules: [
                        {
                          required: true,
                          message:
                            'Please enter your registered company address',
                        },
                      ],
                    },
                  ]"
                />
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item label="WEBSITE">
                <a-input
                  placeholder="Please enter your website"
                  v-decorator="[
                    'website',
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Please enter your website',
                        },
                      ],
                    },
                  ]"
                />
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item label="MESSAGE">
                <a-textarea
                  placeholder="Message to sales"
                  :autosize="{ minRows: 4 }"
                  v-decorator="[
                    'message',
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Please enter your message',
                        },
                      ],
                    },
                  ]"
                />
              </a-form-item>
            </a-col>
          </a-row>
          <a-form-item class="bottom_container">
            <a-button class="goToBtn" type="primary" html-type="submit">
              Submit
            </a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <NewFooter />
  </div>
</template>

<script>
import NavHeader from '@/components/NavHeader.vue';
import NavHeaderMobile from '@/components/NavHeaderMobile.vue';
import NewFooter from '@/components/NewFooter.vue';
import { putContactSales } from '@/utils/api';
import countryList from '@/utils/Country.json';

export default {
  name: 'ContactSales',
  components: {
    NavHeader,
    NavHeaderMobile,
    NewFooter,
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'coordinated' }),
      countryOption: [],
      industryOption: [
        {
          label: 'Administrative Support Services',
          value: 'Administrative Support Services',
        },
        {
          label: 'Arts, Entertainment& Recreation',
          value: 'Arts, Entertainment& Recreation',
        },
        {
          label: 'Charitable/Nonprofits',
          value: 'Charitable/Nonprofits',
        },
        {
          label: 'Education',
          value: 'Education',
        },
        {
          label: 'Financial Services &Products',
          value: 'Financial Services &Products',
        },
        {
          label: 'Healthcare',
          value: 'Healthcare',
        },
        {
          label: 'Hospitality',
          value: 'Hospitality',
        },
        {
          label: 'Information Technology (IT)',
          value: 'Information Technology (IT)',
        },
        {
          label: 'Luxury Good',
          value: 'Luxury Good',
        },
        {
          label: 'Professional & Shared Services',
          value: 'Professional & Shared Services',
        },
        {
          label: 'Real Estate',
          value: 'Real Estate',
        },
        {
          label: 'Retail Consumer',
          value: 'Retail Consumer',
        },
        {
          label: 'Travel &Transportation',
          value: 'Travel &Transportation',
        },
        {
          label: 'Warehousing',
          value: 'Warehousing',
        },
      ],
    };
  },
  created() {
    function convertFunc(item) {
      return {
        value: item.countryCode,
        label: item.name_en,
        code: item.code,
        key: item.name_cn,
      };
    }
    const initCountry = countryList.map(convertFunc);
    this.countryOption = initCountry;
  },
  methods: {
    goPage(value) {
      this.$router.push(value);
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          const params = {
            ...values,
          };
          putContactSales(params).then((res) => {
            if (res.code === 0) {
              this.$message.success(res.msg);
              this.$router.push({
                path: '/business',
              });
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  max-width: 1280px;
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-bottom: 20px;
  h2 {
    font-size: 32px;
    margin-top: 60px;
  }
  .title-tips {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    opacity: 0.6;
  }
  .contact-container {
    margin-top: 60px;
    padding: 20px 60px;
    border-radius: 10px;
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.06);
    .ant-input {
      height: 48px;
    }
    .ant-select {
      width: 100%;
      height: 48px;
      /deep/ .ant-select-selection {
        height: 48px !important;
        .ant-select-selection__rendered {
          height: 48px !important;
          line-height: 48px;
        }
      }
    }
    .bottom_container {
      width: 100%;
      text-align: center;
      .goToBtn {
        border-radius: 8px;
        height: 48px;
        width: 560px;
        @media (max-width: 960px) {
          width: 100%;
        }
      }
    }
  }
  @media (max-width: 960px) {
    width: 100%;
    flex-direction: column;
    .contact-container {
      padding: 20px;
    }
  }
  .agreeSty {
    margin-left: 10px;
  }
}
</style>
